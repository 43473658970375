import React from "react"

import { Page } from "../templates/page"

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Page
      title={"Privacy Policy"}
      description={"MaxRewards privacy policy page"}
    >
      <section className="uk-section uk-section-muted">
        <div className="uk-container uk-container-small">
          <div className="uk-card uk-card-default uk-card-body">
            <p style={{ color: "black" }}>Effective Date: August 29, 2020</p>
            <p>
              This Privacy Policy (“Privacy Policy”) explains MaxRewards, Inc.’s
              (“MaxRewards,” “we” “us” or “our”) privacy practices for any
              information we receive from the MaxRewards mobile application (the
              “App”), the website at maxrewards.co (the “Site”), any other web
              address or hyperlink that redirects to the Site, and any other
              webpage or application controlled by us that links to this Privacy
              Policy and describes the ways in which we use the information we
              receive from you. Certain portions of this Privacy Policy also
              apply to other information collected or maintained by MaxRewards,
              for example, under the “Rights Under the California Consumer
              Privacy Act” and “General Data Protection Regulation (GDPR)”
              sections below.
            </p>
            <p>
              By using the App, the Site or receiving any services of MaxRewards
              (the “Services”) you consent to the terms of this Privacy Policy.
              If you do not agree to the terms and conditions of this Privacy
              Policy, including having your Personal Information (as defined
              below) used in any of the ways described in this Privacy Policy,
              please do not use the Site, the App or the Services. Please note,
              however, that if you don’t provide us with your Personal
              Information, certain parts or features of the Site, App or
              Services may not function properly.
            </p>
            <p>
              MaxRewards may update this Privacy Policy from time-to-time in our
              sole discretion by posting such revised Privacy Policy on the Site
              or within the App. Please note the Effective Date of this Privacy
              Policy set forth above. It is your responsibility to review this
              Privacy Policy regularly for any changes each time that you use
              the App, the Services or provide us information.
            </p>
            <p>
              Use of the App or the Services by you following our posting of a
              new Privacy Policy constitutes your acceptance of the Privacy
              Policy as modified and will apply to all information received
              after the Effective Date of the modification. In the event we
              materially change the way in which we use your Personal
              Information that we previously collected, we will provide you with
              notice and ask you to affirmatively accept the new uses. This
              Privacy Policy is incorporated as part of the Terms and Conditions
              that apply with respect to your use of the App or Services.
            </p>
            <p>
              If you are accessing the services while located in the European
              Union, you may have additional data privacy rights. Please review
              the “General Data Protection Regulation (GDPR)” section below for
              more information.
            </p>
            <h2>Description of Our Business</h2>
            <p>
              MaxRewards provides solutions that allow you to manage your credit
              card accounts and maximize credit card rewards and savings.
              Downloading, installing or using the App, is included in the
              definition of “Services”.
            </p>
            <h3>Collection and Use of Your Personal Information</h3>
            <p>
              We may require that you provide certain Personal Information
              through the App to create or register for a MaxRewards account (an
              “Account”). Information we collect when you register your Account
              may include your name, email address, and other information that
              identifies you or that, in combination with other information we
              hold, may reasonably be used to identify you (“Personal
              Information”).
            </p>
            <p>
              We may also collect Personal Information you provide to us when
              you use the Services, or email or otherwise contact us. We may
              also use your Personal Information to contact you to further
              discuss the Services or any product offerings. You may choose to
              opt out of providing your Personal Information by not disclosing
              it to us or entering it when asked.
            </p>

            <h2>Processing Purposes</h2>

            <p>
              MaxRewards may process the following types of Personal Information
              for the following lawful purposes and based upon the legal
              justification set forth in the parenthetical:
            </p>

            <ol>
              <li>
                Information you provide to us about your credit card products or
                login information from your issuing bank used in connection with
                the App (CONSENT)
              </li>
              <li>
                Processing Personal Information in order to fulfill an order or
                provide Services, e.g., your email address or phone number used
                to login to the App (CONSENT, CONTRACT)
              </li>
              <li>Location data to provide Services (CONSENT)</li>
              <li>
                Your submission of Personal Information when contacting us for
                customer service, to provide feedback or for other purposes not
                otherwise described here (CONSENT)
              </li>
              <li>
                Your submission of Personal Data to our third-party vendors for
                Services (CONSENT, CONTRACT)
              </li>
              <li>
                Collection of Personal Information through the App or through
                the Site (CONSENT)
              </li>
              <li>Payment (CONTRACT)</li>
              <li>Other information you voluntarily provide (CONSENT)</li>
            </ol>
            <p>
              MaxRewards does not collect your credit card number. MaxRewards
              uses the data linked from your credit card account to provide the
              Services. You may choose to manually input your credit card
              product without linking your credit card account, but this may
              prevent you from using the full functionality of the App or
              Services.
            </p>

            <h2>Legal Basis for Processing</h2>

            <p>
              We process your Personal Information with your consent or subject
              to the performance of a contract to which you are a party or,
              alternatively, to take steps at your request to enter into an
              agreement. You have the right to withdraw your consent at any
              time.
            </p>

            <h2>Categories of Personal Information Collected</h2>

            <p>
              Generally, we collect the following data elements from you when we
              process your Personal Information for the purposes we described in
              this Privacy Policy. We collect and process your:
            </p>

            <ul>
              <li>First and last name</li>
              <li>Date of birth (only when provided to us through the Site)</li>
              <li>Email address</li>
              <li>
                Transaction information from issuing banks that you provide,
                e.g., balances, billing, auto-pay enrollment status, credit
                scores and any other information regarding any credit card
                linked to the App
              </li>
              <li>Internet or other electronic network activity</li>
              <li>
                Testimonials and other information you voluntarily provide
              </li>
            </ul>

            <h2>Disclosures and Transfers of Personal Information</h2>

            <p>
              We do not share with or disclose Personal Information to third
              parties, except when one or more of the following conditions is
              true:
            </p>

            <ul>
              <li>We have your permission to make the disclosure;</li>
              <li>
                The disclosure is necessary for the purpose for which the
                Personal Information was obtained;
              </li>
              <li>The disclosure is permitted by relevant law;</li>
              <li>
                The Personal Information to be disclosed is otherwise publicly
                available in accordance with applicable law;
              </li>
              <li>
                The disclosure is reasonably related to the sale or other
                disposition of all or part of our business or assets (see
                Business Transfers);
              </li>
              <li>
                The party to whom the disclosure is made controls, is controlled
                by, or is under common control with MaxRewards;
              </li>
              <li>
                The disclosure is, in our sole discretion, necessary for the
                establishment or maintenance of legal claims or legal
                compliance, to satisfy any law, regulation, subpoena or
                government request, or in connection with litigation;
              </li>
              <li>
                The disclosure is, in our sole discretion, about users who we
                believe are engaged in illegal activities or are otherwise in
                violation of our Terms and Conditions, even without a subpoena,
                warrant or court order; or
              </li>
              <li>
                The disclosure is to outside businesses to perform certain
                services for us, such as maintaining our Site or assisting us in
                the provision of Services including service providers outside
                the country or jurisdiction in which you reside. Any data
                provided to third parties is strictly for MaxRewards’ internal
                business use.
              </li>
            </ul>

            <h2>Delivering Advertising</h2>

            <p>
              We and our service providers may use information about your
              interactions with our Services to predict your interests and
              select the ads you see on and off the App or our Site. This is
              known as interest-based advertising. MaxRewards endeavors to
              comply with the Self-Regulatory Principles for Online Behavioral
              Advertising developed by the Digital Advertising Alliance (“DAA”)
              for any interest-based advertisements. For more information about
              interest-based advertising and how you can opt out, visit:
            </p>

            <ul>
              <li>
                Digital Advertising Alliance:{" "}
                <a href="http://www.aboutads.info/choices/">
                  http://www.aboutads.info/choices/
                </a>
              </li>
              <li>
                Network Advertising Initiative:{" "}
                <a href="http://www.networkadvertising.org/choices/">
                  http://www.networkadvertising.org/choices/
                </a>
              </li>
            </ul>

            <h2>Communications Opt Out</h2>

            <p>
              You may opt out of receiving marketing materials from us at any
              time by clicking the “unsubscribe” or similar link in any
              marketing email. Even after such opt-out, you may receive
              additional emails for a short period of time while we process your
              request. We also may contact you by email or otherwise with
              information that is not primarily marketing, for example,
              regarding updates to the Services or information about your
              Account or any contracts or arrangements. If, at any time after
              registering your information or electing to sign up for the
              Services, you change your mind about receiving information from
              us, send us a request specifying your new choice. Simply send your
              request to{" "}
              <a href="mailto:support@maxrewards.co">support@maxrewards.co</a>.
            </p>

            <h2>California Residents’ Privacy Rights:</h2>

            <p>
              This section is applicable to residents of California. If you are
              a resident of California, you have certain rights described below.
              The following does not apply to individuals who do not live in
              California on a permanent basis.
            </p>

            <p>
              If you are a California resident who has provided personal data to
              us for the purpose of establishing a business relationship for
              personal, family or household purposes (a “California Customer”),
              you may request information about whether we have disclosed your
              personal information to any third parties for such third parties’
              direct marketing purposes. In general, if we have made such a
              disclosure, upon receipt of a request by a California Customer, we
              are required to provide a list of all third parties to whom
              personal information was disclosed in the preceding calendar year,
              as well as the “Categories of Personal Information Collected,”
              above. California Customers may request further information about
              our compliance with this law by mailing us at 75 5th St NW, Suite
              2404, Atlanta, GA 30308 or emailing us at{" "}
              <a href="mailto:support@maxrewards.co">support@maxrewards.co</a>.
              Please note that we are only required to respond to two requests
              per California Customer each year.
            </p>

            <h3>RIGHTS UNDER THE CALIFORNIA CONSUMER PRIVACY ACT</h3>

            <p>
              This section of our Privacy Policy provides California residents
              with a comprehensive description of MaxRewards’s online and
              offline practices regarding the collection, use and disclosure of
              personal information and the rights of California consumers
              regarding their personal information under the California Consumer
              Privacy Act (“CCPA”). This section applies to all California
              residents (but not including legal entities, such as companies).
              The section will not apply, however, if MaxRewards does not
              collect any personal information about you or if all the
              information we collect is exempt from the statute (for example,
              the CCPA does not protect information that is already protected by
              certain other privacy laws, and it does not protect information
              that is already publicly available). For purposes of this section
              regarding the rights of California residents, “personal
              information” does not include publicly available information that
              is lawfully made available to the public from federal, state, or
              local government records; and the words “you” or “your”
              specifically refers to California Consumers.
            </p>

            <h4>
              1. You have a Right to Know About Personal Information Collected,
              Disclosed, or Sold.
            </h4>

            <p>
              You have the right to request that MaxRewards disclose what
              personal information we collect, use and disclose. This is called
              the “Right to Know”. Under the Right to Know, you can request a
              listing of the types of personal information we have collected
              about you, the sources of that information, how we use the
              information, other individuals and business with whom we share
              personal information, and the specific pieces of personal
              information that we have collected about you.
            </p>

            <p>
              If you would like the above information, you may contact us
              through our webpage or customer service. When you make a request
              under your Right to Know, you can expect the following:
            </p>

            <ol type="a">
              <li>
                We will verify your identity. You will need to provide us the
                following information: email address and full name in order for
                us to verify that you are who you say you are.
              </li>
              <li>
                We will confirm our receipt of your request within 10 days. If
                you have not received a response within a few days after that,
                please let us know by contacting us at the webpage or phone
                number listed below.
              </li>
              <li>
                We will respond to your request within 45 days. If necessary, we
                may need an additional period of time, up to another 45 days, to
                process your request.
              </li>
              <li>
                In certain cases, a Request to Know may be denied, for example,
                if we cannot verify your identity or if providing you the
                information could create an unreasonable risk to someone’s
                security (for example, we do not want sensitive information
                disclosed inappropriately). If we deny your request, we will
                explain why we denied it. If we deny a request, we will still
                try to provide you as much of the information as we can, but we
                will withhold the information subject to denial.
              </li>
            </ol>

            <p>
              In the last 12 months, we have collected the categories of
              personal information described above under the section “Categories
              of Personal Information Collected.” All such information was
              collected from sources as described above and for the purposes
              described above. We only disclose such information to third
              parties as described in the section “Disclosures and Transfers of
              Personal Information”. MaxRewards does not sell personal
              information.
            </p>

            <h4>
              2. You have a Right to Request Deletion of Personal Information
              about You.
            </h4>

            <p>
              You have a right to request the deletion of your personal
              information collected or maintained by MaxRewards. You may request
              deletion of your personal information by contacting us through our
              webpage or customer service, as provided below, or by deleting or
              unregistering for the Services via the App. When you make a
              request for deletion, you can expect the following:
            </p>

            <ol type="a">
              <li>
                After you request deletion, you will need to confirm that you
                want your information deleted.
              </li>
              <li>
                We will verify your identity. You will need to provide us the
                following information: email address and full name.
              </li>
              <li>
                We will confirm our receipt of your request within 10 days. If
                you have not received a response within a few days after that,
                please let us know by contacting us at the webpage or phone
                number listed below.
              </li>
              <li>
                We will respond to your request within 45 days. If necessary, we
                may need an additional period of time, up to another 45 days, to
                process your request.
              </li>
              <li>
                In certain cases, a Request for Deletion may be denied, for
                example, if we cannot verify your identity, the law requires
                that we maintain the information, or if we need the information
                for internal purposes such as providing Services or completing
                an order. If we deny your request, we will explain why we denied
                it.
              </li>
            </ol>

            <p>
              Note that simply deleting the app from your mobile device may not
              result in the deletion of your personal information. [Even if you
              request that your personal information be deleted, MaxRewards may
              retain certain information regarding how you use the App or the
              Services for our internal analytics purposes.]
            </p>

            <h4>3. Right to Opt-Out of the Sale of Personal Information</h4>

            <p>
              You have a right to opt-out of the sale of your personal
              information by businesses. MaxRewards does not sell your personal
              information and opt-out is not applicable.
            </p>

            <h4>
              4. Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights
            </h4>

            <p>
              You have a right not to receive discriminatory treatment by us for
              exercising any of the privacy rights conferred by the CCPA. If you
              chose to exercise your rights under the CCPA, we will not:
            </p>

            <ul>
              <li>Deny goods or services.</li>
              <li>
                Charge different prices or rates for goods and services,
                including through the use of discounts or other benefits or
                imposing penalties.
              </li>
              <li>
                Provide a different level or quality of goods or services.
              </li>
              <li>
                Suggest that you will receive a different price or rate for
                goods or services or a different level or quality of goods or
                services.
              </li>
              <li>
                In any other way, discriminate or attempt to discriminate
                against you for the exercise of your rights under the CCPA.
              </li>
            </ul>

            <h4>5. Authorized Agents</h4>

            <p>
              You may designate an authorized agent to make a request under the
              CCPA on your behalf. We will deny requests from agents that do not
              submit proof of authorization from you. To verify that an
              authorized agent has authority to act for you, we may require a
              copy of a power of attorney or require that you provide the
              authorized agent with written permission and verify your own
              identity with us.
            </p>

            <h4>6. Contact Information</h4>

            <p>
              To request additional information, or make any of the requests
              described above, you may contact us through our App or website.
            </p>

            <h2>General Data Protection Regulations (“GDPR”)</h2>

            <p>
              Terms in this section are to be understood in a manner consistent
              with GDPR including the definitions of such terms in the GDPR.
              Such terms may have a different definition or meaning in other
              portions of this Privacy Policy because GDPR may not apply to
              those sections.
            </p>

            <p>This section may apply:</p>

            <ol type="a">
              <li>
                if we collect Personal Data (as defined in the GDPR) that is
                subject to the GDPR; and
              </li>
              <li>
                at least one of the following are true:
                <ol>
                  <li>if you are located in the European Union;</li>
                  <li>
                    if the processing of your data is subject to the laws of a
                    member state of the European Union by virtue of public
                    international law;
                  </li>
                  <li>
                    if your information is otherwise governed by European Union
                    Law; or
                  </li>
                  <li>
                    if the processing activities are related to the offering of
                    goods or services to you in the European Union or the
                    monitoring of your behavior that takes place within the
                    European Union.
                  </li>
                </ol>
              </li>
            </ol>

            <h3>Identification of Data Controller</h3>

            <p>
              The Data Controller is MaxRewards. You may contact the MaxRewards
              Customer Relations Department through the App.
            </p>

            <h3>
              Identification of Data Protection Officer and Contact Details
            </h3>

            <p>Our Data Protection Officer is:</p>

            <p>
              <div>David Gao</div>
              <div>
                <a href="mailto:david.gao@maxrewards.co">
                  david.gao@maxrewards.co
                </a>
              </div>
            </p>

            <h3>
              Identification of Primary Member State Supervisory/Data Protection
              Authority
            </h3>

            <p>
              You have the right to lodge a complaint regarding the processing
              of your Personal Data with us by contacting our Data Protection
              Officer listed above. You also may lodge a complaint with the Data
              Protection Authorities in the Member State where you habitually
              reside, work, or where an infringement occurred.{" "}
            </p>

            <h3>Processing Purposes and Legal Bases</h3>

            <p>
              MaxRewards processes your Personal Data for the lawful purposes,
              and under the legal bases, set forth above.{" "}
            </p>

            <h3>Onward Transfer</h3>

            <p>
              We may disclose Personal Data to subcontractors and/or third-party
              agents. Before disclosing Personal Data to a subcontractor or
              third-party agent we will obtain the following contractual
              assurances:
            </p>

            <ol type="i">
              <li>
                Personal Data will be transferred only for limited and specified
                purposes;
              </li>
              <li>
                the subcontractor or third-party agent will provide at least the
                same level of privacy protection as is required by the GDPR;
              </li>
              <li>
                subcontractors and third-party agents will take reasonable and
                appropriate steps to effectively process Personal Data in a
                manner consistent with the subcontractor or third-party agent’s
                obligations under the GDPR;
              </li>
              <li>
                subcontractors and third-party agents will notify us if they
                determine that they can no longer meet their obligations to
                provide the level of protection required by the GDPR;
              </li>
              <li>
                subcontractors and third-party agents will take reasonable and
                appropriate steps to stop and remediate unauthorized processing;
                and
              </li>
              <li>
                subcontractors and third-party agents will provide a summary or
                a representative copy of the relevant privacy provisions of its
                contract with subcontractors and third-party agents to the
                Supervisory Authorities upon request.
              </li>
            </ol>

            <p>
              MaxRewards may be required to disclose, and may disclose, Personal
              Data in response to lawful requests by public authorities,
              including for the purpose of meeting national security or law
              enforcement requirements, or in the event of a merger or
              acquisition.{" "}
            </p>

            <h3>Retention of Personal Data</h3>

            <p>
              Personal Data obtained by MaxRewards is adequate, relevant and not
              excessive in relation to the purposes described in this Privacy
              Policy. The Personal Data is processed for purposes specified
              herein and will only be processed consistent with the purposes
              described herein. We will request only the minimum amount of
              information required to perform the applicable Services and will
              retain such information only for as long as necessary to provide
              the Services or for compatible purposes, such as to provide
              additional Services, to comply with legal requirements, or to
              preserve or defend our legal rights.
            </p>

            <h3>Right of Access to your Personal Data</h3>

            <p>
              You have the right to access your Personal Data. If such Personal
              Data is inaccurate or processed in violation of the GDPR, you may
              also request that Personal Data be corrected, amended, or deleted.
              When we receive Personal Data, we do so on behalf of the
              individual submitting such Personal Data. To request access to, or
              correction, amendment or deletion of, Personal Data, you should
              contact our Data Protection Officer. We will cooperate with all
              reasonable requests to assist you in exercising your rights under
              the GDPR.
            </p>

            <h3>Choice</h3>

            <p>
              You have the right to opt out of (a) disclosures of your Personal
              Data to third parties not identified at the time of collection or
              subsequently authorized, and (b) uses of Personal Data for
              purposes materially different from those disclosed at the time of
              collection or subsequently authorized. If you wish to limit the
              use or disclosure of your Personal Data, you should submit that
              request to our Data Protection Officer.
            </p>

            <h3>Security</h3>

            <p>
              See “Confidentiality and Security” below for more information
              about our security practices.
            </p>

            <h3>Transfers to the United States from the European Union</h3>

            <p>
              In using the Services, your Personal Data will be transferred to
              the United States, which is not recognized as a country having
              adequate safeguards for the protection of Personal Data.
              MaxRewards relies on Article 49 of the GDPR for transfers of data
              collected from Data Subjects in the EU and EEA. Transfers are made
              to MaxRewards only if the Data Subject has explicitly consented to
              the proposed transfer after having been informed of the possible
              risks of such transfers. Additionally, we transfer data as
              necessary for the performance of a contract between you as the
              Data Subject and MaxRewards as the Controller, to Data Processors
              who have an agreement with us that includes protecting your
              privacy and the security of your data, and in cases where your
              Personal Data is necessary for the implementation of
              pre-contractual measures taken in accordance with your requests.
            </p>

            <h2>Do-Not-Track</h2>

            <p>
              Your browser may offer you a “Do Not Track” option, which allows
              you to signal to operators of websites and web applications and
              services (including behavioral advertising services) that you do
              not wish such operators to track certain of your online activities
              over time and across different websites. Our Site does not support
              Do Not Track requests at this time, which means that we collect
              information about your online activity while you are using our
              Site and may track which website you visit immediately before or
              after accessing our Site.
            </p>

            <h2>Third-Party Websites</h2>

            <p>
              Our Privacy Policy does not apply to services offered by other
              companies or individuals, including products or websites that may
              be displayed to you in search results, websites that may include
              MaxRewards Services, or other websites linked from our Site. This
              Privacy Policy does not cover the information practices of other
              companies and organizations who advertise our Services, and who
              may use cookies, tracking pixels or other technologies to serve
              ads.
            </p>

            <h2>Correcting &amp; Updating Your Information</h2>

            <p>
              To update your information or discontinue the Services, email{" "}
              <a href="mailto:support@maxrewards.co">support@maxrewards.co</a>.
              MaxRewards will respond to your correction or update request
              within at most 30 days from the date of your request. You also may
              be able to update your information by logging into your Account.
            </p>

            <h2>Confidentiality and Security</h2>

            <p>
              We maintain reasonable safeguards to protect the Personal
              Information we collect.&nbsp; While we implement these measures,
              please note that 100% security is not possible, and&nbsp;we cannot
              guarantee that the security measures we have in place to safeguard
              personal information will never be defeated or fail, or that those
              measures will always be sufficient or effective.
            </p>

            <p>
              MaxRewards may assign you a user ID and a password as part of your
              participation and access to the App. Only you may use your user ID
              and password. You may not share your user ID and password with
              anyone else, and you are solely responsible for maintaining and
              protecting the confidentiality of your user ID and password. You
              are fully responsible for all activities that occur under your
              user ID. You play a role in protecting your information as well.
            </p>

            <h2>Social Media Platforms and Websites</h2>

            <p>
              Any information, communications, or material of any type or nature
              that you submit to any third-party websites (including, but not
              limited to any MaxRewards related or MaxRewards controlled pages
              contained on a social media platform, application or website such
              as Facebook or Twitter) by email, posting, messaging, uploading,
              downloading, or otherwise (“Submissions”), is done at your own
              risk and without any expectation of privacy. MaxRewards cannot
              control the actions of other users of any social media platform or
              website and is not responsible for any content or Submissions
              contained on such sites and platforms.
            </p>

            <h2>Visiting our Services from Outside the United States</h2>

            <p>
              Your information may be transferred to, and maintained on,
              computers located outside of your state, province/territory, or
              country where the privacy laws may not be as protective as those
              where you live. If you are located outside the United States and
              choose to provide information to us, please be aware that we
              transfer your Personal Information to the United States and
              process and store it there. As a result, this information may be
              subject to access requests from governments, courts, law
              enforcement officials and national security authorities in the
              United States according to its laws. Subject to the applicable
              laws, we will use reasonable efforts to ensure that appropriate
              protections are in place to maintain protections on the Personal
              Information. By submitting your Personal Information, you consent
              to having your personal data transferred to, processed, and stored
              in the United States.
            </p>

            <h2>Business Transfers</h2>

            <p>
              If we or any of our affiliates is involved in a merger,
              acquisition, asset sale, joint venture or other, similar
              transaction, we will continue to ensure the confidentiality of any
              Personal Information. We may transfer or allow access to Personal
              Information as part of the transaction and in review or
              preparation for it as described below.
            </p>

            <p>In the event that:</p>

            <ul>
              <li>We engage in one more asset sales or purchases;</li>
              <li>We are acquired;</li>
              <li>
                We engage in one or more business combinations, mergers or
                acquisitions;
              </li>
              <li>Substantially all our assets are acquired; or</li>
              <li>We are subject to an event of bankruptcy</li>
            </ul>

            <p>
              you acknowledge and agree that personal and other information
              comprises an asset of ours and that Personal Information and other
              information is subject to assignment, transfer and/or acquisition
              by a third party. Accordingly, and notwithstanding anything to the
              contrary in this Privacy Policy, you acknowledge and agree that
              such transfers may occur and that a person or entity that acquires
              us may continue to subsequently use your Personal Information, and
              that such subsequent use may not be consistent with this Privacy
              Policy.
            </p>

            <h2>Children's Privacy</h2>

            <p>
              We do not knowingly collect or solicit personal information from
              children under the age of 13. By using the Services, you represent
              that you are at least 13 years old. If you are under 13, please do
              not attempt to register for an Account or send any Personal
              Information about yourself to us. If we become aware that we have
              inadvertently received or collected Personal Information from a
              user of the Services who is under the age of 13, we will
              immediately delete that information from our files and records.
              Furthermore, we encourage users of the Services that are minors
              that are 13 years of age or older to ask their parents or
              guardians for permission before sending any information about
              themselves over the Internet.
            </p>

            <p>
              If you believe a child who is under the age of 13 has provided us
              with Personal Information, please contact us at the address listed
              above.
            </p>

            <h2>COOKIE POLICY</h2>

            <p>
              This Cookie Policy is part of the Privacy Policy and explains
              these technologies, including cookies, tracking pixels, web
              beacons, and flash cookies, and how you can control them.
            </p>

            <p>
              The internet is a rapidly changing medium, and the Site may
              include services provided by third parties. The cookies and
              third-party sites listed in this Cookie Policy are intended to
              provide timely information and may be updated from time to time;
              however, due to the nature of the internet, these lists may not
              exactly and inclusively represent all such items at every point in
              time. You should check back periodically to review updates to this
              Cookie Policy.
            </p>

            <p>
              MaxRewards may use cookies and other tracking technologies to
              deliver and improve the Site and our Services, and to display
              relevant content, products, services and advertising. By using the
              Site, you agree that we can use these technologies as described in
              this Privacy Policy, including this Cookie Policy. We hope that
              this Cookie Policy helps you understand, and feel more confident
              about, our use of cookies and other tracking technologies. If you
              have any further queries or requests, please find the appropriate
              support channel by contacting us at support@maxrewards.co.
            </p>

            <h3>1. What is a cookie?</h3>

            <p>
              Cookies are small text files that are stored on your computer or
              mobile device. They are widely used in order to make websites
              work, or work in a better, more efficient way. For example, they
              can recognize you and remember important information that will
              make your use of a website more convenient (e.g., by remembering
              your user preferences).
            </p>

            <h3>2. What cookies do we use?</h3>

            <p>
              Below we list the different types of cookies we may use on the
              Site. The specific cookies and types of cookies we use change
              regularly and the list below may not be complete or accurate. We
              also may use cookies and similar tools to relate your use of the
              Site to personal information obtained about you.
            </p>

            <h4>Strictly necessary cookies.</h4>

            <p>
              These cookies are essential to the Site to enable you to move
              around it and to use its features. Without these cookies, we may
              not be able to provide certain services or features, and the Site
              may not function properly or perform as smoothly for you.
            </p>

            <h4>Functionality cookies.</h4>

            <p>
              To permit your connection to the Site, our servers receive and
              record information about your computer, device, and browser,
              potentially including your IP address, browser type, and other
              software or hardware information. If you access the Site from a
              mobile or other device, we may collect a unique device identifier
              assigned to that device, geolocation data, or other transactional
              information for that device. All of these features help us to
              improve your visit to the Site.
            </p>

            <h4>Analytics cookies.</h4>

            <p>
              We and our service providers may use analytics cookies, which are
              sometimes called performance cookies, to collect information about
              your use of the Site and enable us to improve the way it works.
              Analytics cookies collect information about how you use the Site,
              for instance, which pages you go to most. The information allows
              us to see the overall patterns of usage on the Site, helps us
              record any difficulties you have with the Site and shows us
              whether our advertising is effective or not.
            </p>

            <h3>3. How do third parties use cookies on the site?</h3>

            <p>
              In some circumstances, we may work with third parties to provide
              services on our Site. For example, we use analytics services
              supported by third party companies who generate analytics cookies
              on our behalf. We may not have access to these cookies, although
              we may use statistical information arising from the cookies
              provided by these third parties to customize content and for the
              other purposes described above. These companies also may transfer
              this information to other parties where required to do so by law
              or where such other parties process the information on their
              behalf.
            </p>

            <p>
              Third-party advertisers and other organizations also may use their
              own cookies to collect information about your activities on our
              Site and/or the advertisements you have clicked on. This
              information, which may be combined with other information acquired
              from third parties, may be used by them to serve advertisements on
              our Site and third party sites that they believe are most likely
              to be of interest to you based on content you have viewed.
            </p>

            <p>
              Third-party advertisers also may use this information to measure
              the effectiveness of their advertisements and to track aggregate
              usage statistics.
            </p>

            <p>
              We may not control these third-party cookies, and their use may be
              governed by the privacy policies of the third parties employing
              these cookies. To learn about these cookies and to disable or
              reject third-party cookies, please refer to the relevant third
              party’s website.
            </p>

            <p>
              You can also learn more about controlling cookies in Section 4
              below.
            </p>

            <h3>4. How can you control cookies?</h3>

            <p>
              You may refuse to accept cookies from the Site at any time by
              activating the setting on your browser that allows you to refuse
              cookies. Further information about the procedure to follow to
              disable cookies can be found on your Internet browser provider’s
              website via your help screen. You may wish to refer to
              http://www.allaboutcookies.org/manage-cookies/index.html for
              information on commonly used browsers.
            </p>

            <p>
              Please note that by disabling or blocking any or all cookies, some
              features of the Site may not operate as intended, or you may not
              have access to features or personalization available through the
              Site.
            </p>

            <p>
              Analytics cookies can also be managed via the following links:
            </p>

            <ul>
              <li>
                Google Analytics:{" "}
                <a href="http://tools.google.com/dlpage/gaoptout">
                  http://tools.google.com/dlpage/gaoptout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Page>
  )
}

export default PrivacyPolicyPage
